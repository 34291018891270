import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import { TOKEN } from 'app/app.constants';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CentralDocumentUploadService {
  http = inject(HttpClient);
  authService = inject(AuthService);
  private loadingService = inject(LoadingService);

  BASE_URL = `${environment.ADMIN_BASE_URL}/v1/kyc/add-kyc-document`;
  CSV_BASE_URL = `${environment.ADMIN_BASE_URL}/v1/product`;
  PRODUCT_IMAGE_URL = `${environment.ADMIN_BASE_URL}/v1/product/upload-product-image`;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  uploadKycDocument(formData: any) {
    const uniqueStateUrl = 'add-kyc-document';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(this.BASE_URL, formData, {
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.getToken(),
        }),
      })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  uploadProductImage(formData: any) {
    const uniqueStateUrl = 'upload-product-image';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(this.PRODUCT_IMAGE_URL, formData, {
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.getToken(),
        }),
      })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

   uploadCSVFile(formData: any) {
      const headers = new HttpHeaders();
      headers.append("Content-Type","mutipart/form-data")
      return this.http.post(`${this.CSV_BASE_URL}/upload-csv`, formData, {
         reportProgress: true,
          observe: 'events',
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.authService.getToken(),
          }),
      });
    }
}
